import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const env = process.env;
const config = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize firebase (if isn't already)
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// Initialize Google Auth through Firebase
export const auth = firebase.auth();

// Initialize Cloud Firestore through Firebase
export const db = firebase.firestore();
export const fieldValue = firebase.firestore.FieldValue;

// Initialize Cloud Functions through Firebase
export const functions = firebase.functions();

export default firebase;
