import styled from 'styled-components/macro';

const Button = styled.button<{ active?: boolean }>`
  padding: 8px 16px;

  appearance: none;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.primary[500]};
  color: ${({ theme }) => theme.primary[100]};
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  text-decoration: none;

  /* box-shadow: ${({ active }) => (active ? '1px solid red' : 'none')}; */
  outline: ${({ active, theme }) => (active ? `3px solid ${theme.primary[700]}` : 'none')};

  &:hover {
    background-color: ${({ theme }) => theme.primary[600]};
    color: ${({ theme }) => theme.primary[50]};
    box-shadow: ${({ theme }) => theme.sheetShadow};
  }
`;
export default Button;
