import { DefaultTheme } from 'styled-components/macro';

export const colors = {
  white: '#ffffff',
  black: '#000000',
  primary: {
    50: '#eff6ff',
    100: '#dbeafe',
    200: '#bfdbfe',
    300: '#93c5fd',
    400: '#60a5fa',
    500: '#3b82f6',
    600: '#2563eb',
    700: '#1d4ed8',
    800: '#1e40af',
    900: '#1e3a8a',
  },
  gray: {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    350: '#707f96',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
  },
};

const lightTheme: DefaultTheme = {
  ...colors,
  // Defaults
  ease: {
    standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    decelerate: 'cubic-bezier(0, 0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0, 1, 1)',
  },

  // Semantics
  bgColor: colors.primary[500],
  sheetColor: colors.gray[100],
  textColor: colors.gray[800],
  textFaded: colors.gray[500],
  hoverBg: colors.gray[200],
  dividerColor: colors.gray[300],

  sheetShadow: '2px 4px 8px rgba(23, 23, 23, 0.08), 2px 4px 12px rgba(23, 23, 23, 0.12)',
};

export default lightTheme;
