import React, { useEffect } from 'react';
import { auth, db } from '../lib/firebase';
import { useSetRecoilState } from 'recoil';
import currentUserAtom from './currentUser';
import placesAtom from './placesAtom';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import type Place from '../types/Place';

const Session: React.FC = ({ children }) => {
  const setCurrentUser = useSetRecoilState(currentUserAtom);
  const setPlaces = useSetRecoilState(placesAtom);

  useEffect(
    () =>
      auth.onAuthStateChanged(async user => {
        setCurrentUser(user);
        if (user) {
          if (user && !user.email?.includes('@notebird.')) {
            auth.signOut();
          }
        }
      }),
    [setCurrentUser]
  );

  const [placesDocs] = useCollectionData<Place>(db.collection('_birdcall'));
  // useEffect(() => {
  //   console.log('placesDocs', placesDocs);
  // }, [placesDocs]);
  useEffect(() => {
    placesDocs && setPlaces(placesDocs);
  }, [placesDocs, setPlaces]);

  return <>{children}</>;
};
export default Session;
