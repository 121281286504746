import { useRef, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { functions } from '../../lib/firebase';

// Libs
import { Loader as MapsLoader } from '@googlemaps/js-api-loader';
import { useHistory } from 'react-router-dom';

// Types

// State
import { useRecoilValue, useRecoilState } from 'recoil';
import isDarkThemeAtom from '../../state/isDarkThemeAtom';
import mapAtom from '../../state/mapAtom';
import placesAtom from '../../state/placesAtom';
import { isSuperDuperAdminSelector } from '../../state/currentUser';
import statusFilterAtom from '../../state/statusFilterAtom';

// Common components
import Loader from '../../components/Loader';
import Button from '../../components/Button';

// Markers
// import MapMarkerSvg from '../../svg/marker.svg';
import MapMarkerWaitingSvg from '../../svg/marker_waiting.svg';
import MapMarkerTryAgainSvg from '../../svg/marker_tryagain.svg';
import MapMarkerSkippedSvg from '../../svg/marker_skipped.svg';
import MapMarkerMailedSvg from '../../svg/marker_mailed.svg';
import MapMarkerEmailedSvg from '../../svg/marker_emailed.svg';
import MapMarkerCalledSvg from '../../svg/marker_called.svg';
import MapMarkerSignedUpSvg from '../../svg/marker_signedup.svg';

// Styles
const MapWrapper = styled.div`
  overflow: hidden;

  position: relative;
  width: calc(100% - 24px);
  height: 512px;
  margin: 0 auto;

  box-shadow: ${({ theme }) => theme.sheetShadow};
  border-radius: 4px;
`;
const Map = styled.div`
  width: 100%;
  height: calc(100% + 16px);
`;
const LowerLeftButtons = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  gap: 12px;
`;
const GenerateLeadsButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

// Component
export default function MapSheet() {
  const theme = useTheme();
  const history = useHistory();
  const isDarkTheme = useRecoilValue(isDarkThemeAtom);
  const [map, setMap] = useRecoilState(mapAtom);
  const places = useRecoilValue(placesAtom);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState(false);
  const isSuperDuperAdmin = useRecoilValue(isSuperDuperAdminSelector);
  const statusFilter = useRecoilValue(statusFilterAtom);

  // Map stuff
  const mapRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const loader = new MapsLoader({
      apiKey: process.env.REACT_APP_PLACES_KEY ?? '',
      version: 'weekly',
      libraries: ['places'],
    });
    loader.load().then(() => {
      if (mapRef.current) {
        const map = new google.maps.Map(mapRef.current, {
          mapId: isDarkTheme ? process.env.REACT_APP_MAP_ID_DARK : process.env.REACT_APP_MAP_ID,
          center: { lat: 35.4742, lng: -97.47854 }, // Taylor's home
          zoom: 15,
          disableDefaultUI: true,
        });
        setMap(map);
      }
    });
  }, [isDarkTheme, setMap]);

  // Add markers
  const [markerReduction, setMarkerReduction] = useState(2);
  const markers = useRef<google.maps.Marker[]>([]);
  useEffect(() => {
    markers.current = [];
    if (map) {
      const filteredPlaces =
        statusFilter === 'all' ? places : places.filter(({ status }) => status === statusFilter);
      const reducedPlaces =
        markerReduction === 1
          ? filteredPlaces
          : filteredPlaces.filter((_, idx) => idx % markerReduction === 0);
      for (const place of reducedPlaces) {
        let icon: string;
        switch (place.status) {
          case '⏱️waiting':
            icon = MapMarkerWaitingSvg;
            break;
          case '🔄try again':
            icon = MapMarkerTryAgainSvg;
            break;
          case '⏭️️skipped':
            icon = MapMarkerSkippedSvg;
            break;
          case '📬mailed':
            icon = MapMarkerMailedSvg;
            break;
          case '✉️emailed':
            icon = MapMarkerEmailedSvg;
            break;
          case '🤙called':
            icon = MapMarkerCalledSvg;
            break;
          case '🐦signed up':
            icon = MapMarkerSignedUpSvg;
            break;
        }
        const marker = new google.maps.Marker({
          position: place.latLng,
          map,
          icon,
          opacity: 0.85,
          optimized: true,
        });
        marker.addListener('click', () => {
          history.push(`/${place.placeId}`);
          map.panTo(place.latLng);
        });
        marker.addListener('mouseover', () => marker.setOpacity(1));
        marker.addListener('mouseout', () => marker.setOpacity(0.85));
        markers.current.push(marker);
      }
    }

    return () => {
      for (const marker of markers.current) {
        marker.setMap(null);
      }
    };
  }, [history, map, markerReduction, places, statusFilter]);

  // Add center circle
  const [mapCircle, setMapCircle] = useState<google.maps.Circle | null>(null);
  useEffect(() => {
    if (map) {
      const mapCircle = new google.maps.Circle({
        strokeColor: theme.primary[500],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: theme.primary[400],
        fillOpacity: 0.35,
        map,
        center: map.getCenter(),
        radius: 1000,
        editable: true,
        visible: isSuperDuperAdmin,
      });
      setMapCircle(mapCircle);
    }
  }, [isSuperDuperAdmin, map, theme]);

  return (
    <MapWrapper>
      <Loader show={isLoadingPlaces} />
      <Map ref={mapRef} />
      {isSuperDuperAdmin && (
        <>
          <LowerLeftButtons>
            <Button
              onClick={() => {
                const center = map?.getCenter();
                if (center && mapCircle) {
                  mapCircle.setCenter(center);
                }
              }}
            >
              📍center
            </Button>
            {/* Marker Reducer */}
            {[1, 2, 4, 8, 16].map(idx => (
              <Button
                onClick={() => {
                  setMarkerReduction(idx);
                }}
                active={markerReduction === idx}
              >
                {idx === 1 ? 'All' : `1/${idx}`}
              </Button>
            ))}
          </LowerLeftButtons>
          <GenerateLeadsButton
            onClick={async () => {
              const location = mapCircle?.getCenter()?.toJSON();
              const radius = mapCircle?.getRadius();
              if (location && radius) {
                setIsLoadingPlaces(true);
                try {
                  await functions.httpsCallable('fetchBircallPlaces')({ location, radius });
                } catch (error) {
                  console.error('error', error);
                }
                setIsLoadingPlaces(false);
              }
            }}
          >
            ⛪generate leads
          </GenerateLeadsButton>
        </>
      )}
    </MapWrapper>
  );
}
