import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import Session from './state/Session';
import { RecoilRoot, useRecoilValue } from 'recoil';

import { ThemeProvider } from 'styled-components';
import lightTheme from './styles/lightTheme';
import darkTheme from './styles/darkTheme';
import BaseStyles from './styles/BaseStyles';
import Normalize from './styles/Normalize';

import App from './App';
import isDarkThemeAtom from './state/isDarkThemeAtom';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RecoilRoot>
        <Session>
          <ThemeWrapper />
        </Session>
      </RecoilRoot>
    </Router>
  </React.StrictMode>,
  document.getElementById('app-root')
);

function ThemeWrapper() {
  const isDarkTheme = useRecoilValue(isDarkThemeAtom);
  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <Normalize />
      <BaseStyles />
      <App />
    </ThemeProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
