import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { db, fieldValue } from '../../lib/firebase';

// Libs
import Papa from 'papaparse';
import orderBy from 'lodash/orderBy';
import { NavLink } from 'react-router-dom';
import chunk from 'lodash/chunk';

// Types
import { PLACE_STATUSES } from '../../types/Place';

// State
import { useRecoilState, useRecoilValue } from 'recoil';
import Scrollable from '../../components/Scrollable';
import placesAtom from '../../state/placesAtom';
import { isSuperDuperAdminSelector, uidSelector } from '../../state/currentUser';
import mapAtom from '../../state/mapAtom';
import statusFilterAtom from '../../state/statusFilterAtom';

// Components
import Sheet from '../../components/Sheet';
import SheetHeading from '../../components/SheetHeading';
import Divider from '../../components/Divider';
import Row from '../../components/Row';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

// Styles
const PlaceLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  &:hover {
    background-color: ${({ theme }) => theme.hoverBg};
  }
  &.active {
    font-weight: 600;
    color: ${({ theme }) => theme.primary[500]};
  }
  span {
    white-space: nowrap;
    padding-left: 16px;
  }
`;
const Select = styled.select`
  font-size: 16px;
  border-radius: 4px;
  padding: 4px;
  background-color: ${({ theme }) => theme.sheetColor};
  border: 2px solid ${({ theme }) => theme.dividerColor};
  color: ${({ theme }) => theme.textColor};
`;
const Notice = styled.div`
  text-align: center;
  padding: 32px;
`;

// Component
export default function PlacesSheet() {
  const places = useRecoilValue(placesAtom);
  const uid = useRecoilValue(uidSelector);
  const isSuperDuperAdmin = useRecoilValue(isSuperDuperAdminSelector);
  const map = useRecoilValue(mapAtom);
  const [statusFilter, setStatusFilter] = useRecoilState(statusFilterAtom);

  const halfWaitingPlaces = useMemo(() => {
    const waitingPlaces = places.filter(({ status }) => status === '⏱️waiting');
    const total = waitingPlaces.length;
    return waitingPlaces.slice(0, Math.floor(total / 2));
  }, [places]);
  const csvHref = useMemo(() => {
    const csvFormat = halfWaitingPlaces.map(
      ({ name, address1, address2, city, state, zip, phone, website }) => ({
        'First Name': name,
        'Last Name': '',
        'Address 1': address1,
        'Address 2': address2,
        City: city,
        State: state,
        'Zip Code': zip,
        Phone: phone,
        Website: website,
      })
    );
    return `data:text/csv;charset=utf-8,${encodeURI(Papa.unparse(csvFormat))}`;
  }, [halfWaitingPlaces]);

  const placeStatusTotals = useMemo(() => {
    const totals = PLACE_STATUSES.map(status => {
      const total = places.filter(place => place.status === status).length;
      return { status, total };
    });
    return orderBy(totals, 'total', 'desc');
  }, [places]);

  const filteredPlaces = useMemo(() => {
    const filteredPlaces =
      statusFilter === 'all' ? places : places.filter(({ status }) => status === statusFilter);
    const orderedPlaces = orderBy(
      filteredPlaces,
      ({ createdAt, activity }) => {
        if (activity.length) {
          return activity[activity.length - 1].createdAt;
        }
        return createdAt;
      },
      'desc'
    );
    return orderedPlaces;
  }, [places, statusFilter]);

  return (
    <Sheet>
      {/* Title */}
      <Row align='flex-end' padding='32px 24px 0'>
        <SheetHeading>
          <div>
            places <span>({filteredPlaces.length})</span>
          </div>
          <Select
            value={statusFilter}
            onChange={e => setStatusFilter(e.currentTarget.value as any)}
          >
            <option value='all'>📍all places ({places.length})</option>
            {placeStatusTotals.map(({ status, total }) => (
              <option key={status} value={status}>
                {status} ({total})
              </option>
            ))}
          </Select>
          {isSuperDuperAdmin && halfWaitingPlaces.length >= 50 && (
            <Button
              as='a'
              href={csvHref}
              download={`bircall_list_${new Date().toISOString().split('T')[0]}.csv`}
              onClick={async () => {
                for (const placesChunk of chunk(halfWaitingPlaces, 250)) {
                  const batch = db.batch();
                  for (const place of placesChunk) {
                    const ref = db.collection('_birdcall').doc(place.placeId);
                    const update = {
                      status: '📬mailed',
                      activity: fieldValue.arrayUnion({
                        type: '📬mailed',
                        createdAt: new Date(),
                        createdBy: uid,
                      }),
                    };
                    batch.update(ref, update);
                  }
                  await batch.commit();
                }
              }}
            >
              get {halfWaitingPlaces.length} csv mailers
            </Button>
          )}
        </SheetHeading>
      </Row>
      <Loader show={!places.length} />
      <Divider width='calc(100% - 48px)' />
      {/* List */}
      <Scrollable>
        {filteredPlaces.map(place => (
          <PlaceLink
            key={place.placeId}
            to={place.placeId}
            onClick={() => map?.panTo(place.latLng)}
          >
            {place.name}
            <span>{place.status}</span>
          </PlaceLink>
        ))}
        {!!places.length && !filteredPlaces.length && <Notice>Nothing to see here!</Notice>}
      </Scrollable>
    </Sheet>
  );
}
