import { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import { db, fieldValue } from '../../lib/firebase';

// Types
import type Place from '../../types/Place';
import { PLACE_RESPONSES, PLACE_STATUSES } from '../../types/Place';

// State
import { useRecoilValue } from 'recoil';
import { uidSelector } from '../../state/currentUser';

// Libs
import { Link, useParams } from 'react-router-dom';
import { useDocumentData } from 'react-firebase-hooks/firestore';

// Common components
import Sheet from '../../components/Sheet';
import SheetHeading from '../../components/SheetHeading';
import Divider from '../../components/Divider';
import Row from '../../components/Row';
import Scrollable from '../../components/Scrollable';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Spacer, { HSpacer } from '../../components/Spacer';
import Expanded from '../../components/Expanded';
import { relativeDateTime } from '../../lib/helpers';

// Styles
const SecondaryButton = styled.button<{ active?: boolean }>`
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  white-space: nowrap;

  font-size: 14px;
  color: ${({ active, theme }) => (active ? theme.primary[500] : theme.textFaded)};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
`;
const Item = styled.div`
  font-size: 16px;
  padding: 0 0 8px;
`;
const ActivityItem = styled.div`
  text-align: right;
  font-size: 12px;
  padding: 0 0 8px;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textColor};
  }
`;

const EmailInput = styled.input`
  width: 100%;
  font-size: 16px;
  background-color: ${({ theme }) => theme.hoverBg};
  border: 2px solid ${({ theme }) => theme.hoverBg};
  border-radius: 4px;
  outline: none;
  padding: 4px 12px;
  flex: 1;
  color: ${({ theme }) => theme.textColor};

  :focus {
    background-color: ${({ theme }) => theme.sheetColor};
    border: 2px solid ${({ theme }) => theme.primary[500]};
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  font-size: 16px;
  background-color: ${({ theme }) => theme.hoverBg};
  border: 2px solid ${({ theme }) => theme.hoverBg};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  flex: 1;
  color: ${({ theme }) => theme.textColor};
  resize: none;

  :focus {
    background-color: ${({ theme }) => theme.sheetColor};
    border: 2px solid ${({ theme }) => theme.primary[500]};
  }
`;
const ImageRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
const Img = styled.img`
  max-width: 256px;
  max-height: 256px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.dividerColor};
`;

// Component
export default function PlaceSheet() {
  let { id } = useParams<{ id: string }>();
  const uid = useRecoilValue(uidSelector);
  const placeRef = useMemo(() => db.collection('_birdcall').doc(id), [id]);
  const [place, placeLoading] = useDocumentData<Place>(placeRef);
  const [notesInput, setNotesInput] = useState('');
  const notesHaveFocus = useRef(false);
  useEffect(() => {
    !!place && !placeLoading && !notesHaveFocus.current && setNotesInput(place.notes);
  }, [place, placeLoading]);

  const [newUserEmailInput, setNewUserEmailInput] = useState('');

  return (
    <Sheet>
      <Row align='center' padding='32px 24px 0'>
        <SheetHeading>
          {placeLoading && 'loading...'}
          {!placeLoading && !place && 'not found'}
          {place?.name}{' '}
          <Button as={Link} to='/'>
            close
          </Button>
        </SheetHeading>
      </Row>
      {/* Link to Notebird admin */}
      {place?.organizationId && (
        <Row align='flex-end' padding='0 24px 12px'>
          <a
            href={`https://my.notebird.app/admin/${place.organizationId}`}
            target='_blank'
            rel='noreferrer'
          >
            ↗️open in notebird admin
          </a>
        </Row>
      )}
      <Loader show={placeLoading} />
      <Divider width='calc(100% - 48px)' />
      {!!place && (
        <>
          {/* Status */}
          {place.status !== '🐦signed up' && (
            <>
              <Row padding='8px 24px'>
                {PLACE_STATUSES.map(status => {
                  if (status === '⏱️waiting') return null;
                  if (status === '📬mailed') return null;
                  if (status === '🐦signed up') return null;
                  return (
                    <SecondaryButton
                      key={status}
                      active={place.status === status}
                      onClick={() => {
                        if (place.status !== status) {
                          placeRef.update({
                            status,
                            activity: fieldValue.arrayUnion({
                              type: status,
                              createdAt: new Date(),
                              createdBy: uid,
                            }),
                          });
                        }
                      }}
                    >
                      {status}
                    </SecondaryButton>
                  );
                })}
              </Row>
              <Divider width='calc(100% - 48px)' />
            </>
          )}

          {/* Response */}
          {place.status !== '⏱️waiting' && (
            <>
              <Row padding='8px 24px'>
                {PLACE_RESPONSES.map(response => (
                  <SecondaryButton
                    key={response}
                    active={place.response === response}
                    onClick={() => place.response !== response && placeRef.update({ response })}
                  >
                    {response}
                  </SecondaryButton>
                ))}
              </Row>
              <Divider width='calc(100% - 48px)' />
            </>
          )}

          {/* Main content */}
          <Scrollable padding='16px 24px'>
            {/* Info + Activity */}
            <Row>
              {/* Info */}
              <Expanded>
                {/* Phone */}
                <Item>
                  ☎️<a href={`tel:${place.phone}`}>{place.phone}</a>
                </Item>
                {/* Website */}
                <Item>
                  🌐
                  <a href={place.website} target='_blank' rel='noreferrer'>
                    {place.website}
                  </a>
                </Item>
                {/* Address ( links to Google Maps ) */}
                <Item>
                  📍
                  <a
                    href={`https://www.google.com/maps/place/?q=place_id:${id}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {place.address1}, {place.city}, {place.state} {place.zip}
                  </a>
                </Item>
              </Expanded>

              {/* Activity */}
              <HSpacer width='24px' />
              <div>
                <ActivityItem>
                  <strong>added</strong> {relativeDateTime(place.createdAt.toDate())}
                </ActivityItem>
                {place.activity.map((activity, idx) => (
                  <ActivityItem key={idx}>
                    <strong>{activity.type}</strong> {relativeDateTime(activity.createdAt.toDate())}
                  </ActivityItem>
                ))}
              </div>
            </Row>
            <Spacer height='16px' />

            {/* Email addresses */}
            {place.emails.map((email, idx) => (
              <Row align='center' padding='0 0 8px' key={idx}>
                <Expanded>{email.address}</Expanded>
                <HSpacer width='12px' />
                {/* {place.status !== '🐦signed up' && (
                  <>
                    {!email.inIntercom && <Button>add to intercom</Button>}
                    <HSpacer width='12px' />
                  </>
                )} */}
                <Button onClick={() => placeRef.update('emails', fieldValue.arrayRemove(email))}>
                  remove
                </Button>
              </Row>
            ))}
            {/* New email form */}
            {place.emails.length < 10 && place.status !== '🐦signed up' && (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (newUserEmailInput) {
                    placeRef.update(
                      'emails',
                      fieldValue.arrayUnion({ address: newUserEmailInput, inIntercom: false })
                    );
                    setNewUserEmailInput('');
                  }
                }}
              >
                <Row align='center'>
                  <EmailInput
                    placeholder='potential user email'
                    value={newUserEmailInput}
                    onChange={e => {
                      setNewUserEmailInput(e.currentTarget.value.split(' ').join('').toLowerCase());
                    }}
                    autoComplete='off'
                  />
                  <HSpacer width='12px' />
                  <Button type='submit'>Add email</Button>
                </Row>
                <Spacer height='16px' />
              </form>
            )}

            {/* Admin notes */}
            <Textarea
              placeholder='admin notes'
              rows={6}
              value={notesInput}
              onChange={e => setNotesInput(e.currentTarget.value)}
              onFocus={() => (notesHaveFocus.current = true)}
              onBlur={e => {
                db.collection('_birdcall').doc(id).update('notes', e.currentTarget.value.trim());
                notesHaveFocus.current = false;
              }}
            />
            <Spacer height='24px' />

            {/* Images */}
            <ImageRow>
              {place.photoRefs.map(ref => (
                <Img
                  key={ref}
                  src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${ref}&key=${process.env.REACT_APP_PLACES_KEY}`}
                  alt=''
                />
              ))}
            </ImageRow>
          </Scrollable>
        </>
      )}
    </Sheet>
  );
}
