import { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

// Libs
import { auth } from '../../lib/firebase';

// Common components
import Sheet from '../../components/Sheet';
import SheetHeading from '../../components/SheetHeading';
import Divider from '../../components/Divider';
import Row from '../../components/Row';
import Button from '../../components/Button';
import Spacer from '../../components/Spacer';

// Styles
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
`;
const Label = styled.label`
  padding-bottom: 12px;

  input {
    display: block;
    width: 100%;

    font-size: 18px;
    background-color: ${({ theme }) => theme.hoverBg};
    border: 2px solid ${({ theme }) => theme.hoverBg};
    border-radius: 4px;
    outline: none;
    margin-top: 4px;
    padding: 8px 12px;
    flex: 1;
    color: ${({ theme }) => theme.textColor};
    :focus {
      background-color: ${({ theme }) => theme.sheetColor};
      border: 2px solid ${({ theme }) => theme.primary[500]};
    }
  }
`;
const ErrorMessage = styled.div`
  padding: 24px;
`;

// Component
export default function LoginSheet() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setError('');
      try {
        await auth.signInWithEmailAndPassword(email, password);
      } catch (err) {
        console.error('err', err);
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('Unknown error');
        }
      }
    },
    [email, password]
  );

  return (
    <Sheet>
      <Row align='flex-end' padding='32px 24px 0'>
        <SheetHeading>login</SheetHeading>
      </Row>
      <Divider width='calc(100% - 48px)' />
      <Form onSubmit={handleSubmit}>
        <Label>
          email:
          <input value={email} onChange={e => setEmail(e.currentTarget.value)} />
        </Label>
        <Label>
          password:
          <input
            type='password'
            value={password}
            onChange={e => setPassword(e.currentTarget.value)}
          />
        </Label>
        <Spacer height='12px' />
        <Button type='submit'>login</Button>
      </Form>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Sheet>
  );
}
