import styled from 'styled-components/macro';

import { useRecoilValue } from 'recoil';

import isDarkThemeAtom from './state/isDarkThemeAtom';
import darkTheme from './styles/darkTheme';

import Nav from './components/Nav';
import PlacesSheet from './sheets/PlacesSheet';
import MapSheet from './sheets/MapSheet';
import { Route, Switch } from 'react-router-dom';
import PlaceSheet from './sheets/PlaceSheet';
import currentUserAtom, { isSuperDuperAdminSelector } from './state/currentUser';
import LoginSheet from './sheets/LoginSheet';
import SearchSheet from './sheets/SearchSheet';
import { useState } from 'react';
import Button from './components/Button';

// Styles
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  height: 100%;
  max-width: 1280px;
  @media (max-width: 840px) {
    max-width: 540px;
  }
`;
const DarkThemeBg = styled.div<{ active: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${darkTheme.bgColor};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 500ms ${({ theme }) => theme.ease.standard};
`;

const SheetsWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  gap: 16px;
  padding: 0 12px;
  @media (max-width: 840px) {
    display: block;
    overflow: auto;
  }
  @media (max-width: 420px) {
    padding: 0;
  }
`;

// Component
export default function App() {
  const isDarkTheme = useRecoilValue(isDarkThemeAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const isSuperDuperAdmin = useRecoilValue(isSuperDuperAdminSelector);
  const [mapExpanded, setMapExpanded] = useState(false);

  return (
    <>
      <DarkThemeBg active={isDarkTheme} />

      <PageWrapper>
        <Nav />

        {/* Login if not logged in */}
        {!currentUser && (
          <SheetsWrapper>
            <LoginSheet />
          </SheetsWrapper>
        )}

        {/* App if logged in */}
        {!!currentUser && (
          <>
            {isSuperDuperAdmin && (
              <>
                <Button onClick={() => setMapExpanded(prev => !prev)}>
                  {mapExpanded ? 'hide' : 'show'} map
                </Button>
                {mapExpanded && <MapSheet />}
              </>
            )}

            {/* Sheets */}
            <SheetsWrapper>
              {/* To Contact Sheet */}
              <PlacesSheet />

              {/* Mailed Sheet */}
              {/* <MailedSheet /> */}

              {/* Contacted Sheet */}
              {/* <ContactedSheet /> */}
              {/* Individual place */}
              <Switch>
                <Route path='/:id'>
                  <PlaceSheet />
                </Route>
                <Route path='/'>
                  <SearchSheet />
                </Route>
              </Switch>
            </SheetsWrapper>
          </>
        )}
      </PageWrapper>
    </>
  );
}
