import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// Libs
import Fuse from 'fuse.js';

// State
import { useRecoilValue } from 'recoil';
import placesAtom from '../../state/placesAtom';

// Common components
import Sheet from '../../components/Sheet';
import SheetHeading from '../../components/SheetHeading';
import Row from '../../components/Row';
import Button from '../../components/Button';
import { HSpacer } from '../../components/Spacer';
import Scrollable from '../../components/Scrollable';

// Styles
const Input = styled.input`
  font-size: 18px;
  background-color: ${({ theme }) => theme.hoverBg};
  border: 2px solid ${({ theme }) => theme.hoverBg};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  flex: 1;
  color: ${({ theme }) => theme.textColor};
  :focus {
    background-color: ${({ theme }) => theme.sheetColor};
    border: 2px solid ${({ theme }) => theme.primary[500]};
  }
`;
const PlaceLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  &:hover {
    background-color: ${({ theme }) => theme.hoverBg};
  }
  &.active {
    font-weight: 600;
    color: ${({ theme }) => theme.primary[500]};
  }
  small {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.textFaded};
  }
`;

// Component
export default function SearchSheet() {
  const places = useRecoilValue(placesAtom);
  const [searchInput, setSearchInput] = useState('');
  const fuse = useMemo(
    () =>
      new Fuse(places, {
        keys: ['name', 'website', 'phone', 'email', 'notes'],
        includeMatches: true,
        threshold: 0.3,
      }),
    [places]
  );
  const searchResults = useMemo(() => fuse.search(searchInput), [fuse, searchInput]);

  return (
    <Sheet>
      <Row align='center' padding='32px 24px 0'>
        <SheetHeading>
          <Input
            autoFocus
            placeholder='search'
            value={searchInput}
            onChange={e => setSearchInput(e.currentTarget.value)}
          />
          <HSpacer width='12px' />
          {!!searchInput.trim() && <Button onClick={() => setSearchInput('')}>clear</Button>}
        </SheetHeading>
      </Row>
      {/* <Divider width='calc(100% - 48px)' /> */}
      <Scrollable>
        {searchResults.map(result => {
          const place = result.item;
          const website = result.matches?.find(({ key }) => key === 'website');
          const phone = result.matches?.find(({ key }) => key === 'phone');
          const email = result.matches?.find(({ key }) => key === 'email');
          const notes = result.matches?.find(({ key }) => key === 'notes');
          const latestActivity = place.activity.length
            ? place.activity[place.activity.length - 1].createdAt
            : place.createdAt;
          const latestActivityPrint = latestActivity.toDate().toLocaleString('en-US', {
            // year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          });
          return (
            <PlaceLink key={place.placeId} to={`/${place.placeId}`}>
              <div>
                {place.name}
                {website && <small>{website.value}</small>}
                {phone && <small>{phone.value}</small>}
                {email && <small>{email.value}</small>}
                {notes && <small>{notes.value}</small>}
              </div>
              <span>
                {place.status}
                <small>{latestActivityPrint}</small>
              </span>
            </PlaceLink>
          );
        })}
        {!!searchInput.trim() && !searchResults.length && <Row padding='24px 32px'>No results</Row>}
      </Scrollable>
    </Sheet>
  );
}
