import firebase from '../lib/firebase';

export const PLACE_STATUSES = [
  '⏱️waiting',
  '🔄try again',
  '⏭️️skipped',
  '📬mailed',
  '✉️emailed',
  '🤙called',
  '🐦signed up',
] as const;
export type PlaceStatus = typeof PLACE_STATUSES[number];

export const PLACE_RESPONSES = ['⛔none', '😒cold', '😃receptive'];
export type PlaceResponse = typeof PLACE_RESPONSES[number];

export default interface Place {
  name: string;
  placeId: string;
  organizationId: string | null;
  photoRefs: string[];
  latLng: {
    lat: number;
    lng: number;
  };
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  website: string;
  domain: string;
  phone: string;
  emails: { address: string; inIntercom: boolean }[];
  createdAt: firebase.firestore.Timestamp;
  status: PlaceStatus;
  response: PlaceResponse;
  notes: string;
  activity: {
    type: PlaceStatus;
    createdAt: firebase.firestore.Timestamp;
    createdBy: string;
  }[];
}

export const DEFAULT_PLACE = (): Place => ({
  name: '',
  placeId: '',
  organizationId: null,
  photoRefs: [],
  latLng: { lat: 0, lng: 0 },
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  website: '',
  domain: '',
  phone: '',
  emails: [],
  createdAt: firebase.firestore.Timestamp.now(),
  status: '⏱️waiting',
  response: '⛔none',
  notes: '',
  activity: [],
});
