import styled from 'styled-components/macro';
const SheetHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;

  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.015em;

  span {
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.textFaded};
  }
`;
export default SheetHeading;
