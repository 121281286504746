import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { auth } from '../lib/firebase';

// State
import { useRecoilState } from 'recoil';
import theme from '../state/isDarkThemeAtom';

// Common components
import { ReactComponent as BirdcallSvg } from '../svg/birdcall.svg';
import { ReactComponent as BirdcallDarkThemeSvg } from '../svg/birdcall_dark_theme.svg';
import { ReactComponent as DarkThemeSvg } from '../svg/dark_theme.svg';
import { ReactComponent as LightThemeSvg } from '../svg/light_theme.svg';
import Expanded from '../components/Expanded';

// Styles
const NavWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 12px 16px;
`;
const Logo = styled(BirdcallSvg)`
  height: 24px;
`;
const DarkThemeLogo = styled(BirdcallDarkThemeSvg)<{ active: boolean }>`
  position: absolute;
  height: 24px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 500ms ${({ theme }) => theme.ease.standard};
  pointer-events: none;
`;

const ThemeSelector = styled.button<{ active: boolean }>`
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-left: 12px;

  opacity: ${({ active }) => (active ? 1 : 0.5)};
  &:hover {
    opacity: 1;
  }
  transition: opacity 250ms ${({ theme }) => theme.ease.standard};

  /* Highlight */
  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(${({ active }) => (active ? 1 : 0)})
      translateX(${({ active }) => (active ? 0 : 42)}px);
    transition: transform 250ms ${({ theme }) => theme.ease.standard};
  }
  /* Icon */
  svg {
    position: relative;
    width: 24px;
    height: 24px;
  }
`;
const LightThemeSelector = styled(ThemeSelector)`
  /* Highlight */
  div {
    background-color: ${({ theme }) => theme.gray[800]};
  }
  /* Icon */
  svg {
  }
`;
const DarkThemeSelector = styled(ThemeSelector)`
  /* Highlight */
  div {
    transform: scale(${({ active }) => (active ? 1 : 0)})
      translateX(${({ active }) => (active ? 0 : -42)}px);
    background-color: ${({ theme }) => theme.primary[300]};
  }
  /* Icon */
  svg {
  }
`;

// Component
export default function Nav() {
  const [isDarkTheme, setDarkTheme] = useRecoilState(theme);
  const [logoClicks, setLogoClicks] = useState(0);
  useEffect(() => {
    if (logoClicks >= 5) {
      auth.signOut();
      setLogoClicks(0);
    }
  }, [logoClicks]);

  return (
    <NavWrapper>
      {/* Logo */}
      <Logo onClick={() => setLogoClicks(prev => prev + 1)} />
      <DarkThemeLogo active={isDarkTheme} />

      <Expanded />

      {/* Select light theme */}
      <LightThemeSelector active={!isDarkTheme} onClick={() => setDarkTheme(false)}>
        <div />
        <LightThemeSvg />
      </LightThemeSelector>
      {/* Select dark theme */}
      <DarkThemeSelector active={isDarkTheme} onClick={() => setDarkTheme(true)}>
        <div />
        <DarkThemeSvg />
      </DarkThemeSelector>
    </NavWrapper>
  );
}
