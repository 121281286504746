import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import isDarkThemeAtom from '../state/isDarkThemeAtom';
import darkTheme from '../styles/darkTheme';
import lightTheme from '../styles/lightTheme';

// Styles
const SheetWrapper = styled.div<{ isDarkTheme?: boolean }>`
  position: relative;
  flex: 1;
  /* width: 50%; */
  margin: 12px auto;
  height: 100%;
  max-height: calc(100% - 24px);

  max-width: 768px;
  /* max-height: calc(100% - 32px); */
  /* padding: 32px 24px 0; */
  /* @media (max-width: 420px) {
    padding: 32px 16px 0;
  } */
  overflow: hidden;

  border-radius: 4px;

  background-color: ${lightTheme.sheetColor};
  box-shadow: ${({ theme }) => theme.sheetShadow};

  div.darkThemeBg {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${darkTheme.sheetColor};
    border-radius: 3.5px;

    opacity: ${({ isDarkTheme }) => (isDarkTheme ? 1 : 0)};
    transition: opacity 500ms ${({ theme }) => theme.ease.standard};
  }
  div.content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
`;

// Component
interface SheetProps {
  children?: React.ReactNode;
}
export default function Sheet({ children }: SheetProps) {
  const isDarkTheme = useRecoilValue(isDarkThemeAtom);

  return (
    <SheetWrapper isDarkTheme={isDarkTheme}>
      <div className='darkThemeBg' />
      <div className='content'>{children}</div>
    </SheetWrapper>
  );
}
